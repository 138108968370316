import { React, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  sendPostRequest,
  useGetRequest,
  sendPostRequestMultipart,
} from "../../Client";
import { useMutation } from "@tanstack/react-query";
import { imageURL } from "../../config";

export default function CustomerInventoryUpdate(props) {
  let customerID = props.customerID;

  const { status: statusDropdownCategories1, data: dataDropdownCategories1 } =
    useGetRequest(
      "inventory/customer/categories/1/" + customerID + "/" + props.divisionID,
      {}
    );

  const { status: statusDropdownCategories2, data: dataDropdownCategories2 } =
    useGetRequest(
      "inventory/customer/categories/2/" + customerID + "/" + props.divisionID,
      {}
    );

  const { status: statusDropdownCategories3, data: dataDropdownCategories3 } =
    useGetRequest(
      "inventory/customer/categories/3/" + customerID + "/" + props.divisionID,
      {}
    );

  const { status: statusTypePiece, data: dataTypePiece } = useGetRequest(
    "inventory/typepiece/" + customerID,
    {}
  );

  const [locations, setLocations] = useState([]);

  function getCheckedLocation(value) {
    if (locations?.includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  function addRemoveLocation(value) {
    // const locationValue = Number(value);
    const locationValue = value;
    if (locationValue) {
      if (locations?.includes(value)) {
        const locationWithoutThisValue = locations?.filter((x) => x != value);
        setLocations(locationWithoutThisValue);
      } else {
        setLocations([...locations, value]);
      }
    }
  }

  function setInitialLocations(locations) {
    if (locations) {
      return locations.split(",");
    } else {
      return [];
    }
  }

  const { status: statusLocations, data: dataLocations } = useGetRequest(
    "locations",
    {}
  );

  // updating
  const [productCode, setProductCode] = useState();
  const [productDescription, setProductDescription] = useState();
  const [productCategory, setProductCategory] = useState();
  const [productUnitPrice, setProductUnitPrice] = useState();
  const [adminOnly, setAdminOnly] = useState(undefined);
  const [image, setImage] = useState();
  const [deleteImage, setDeleteImage] = useState(false);
  const [image2, setImage2] = useState();
  const [deleteImage2, setDeleteImage2] = useState(false);
  const [division, setDivision] = useState(false);
  const [category1, setCategory1] = useState(false);
  const [category2, setCategory2] = useState(false);
  const [category3, setCategory3] = useState(false);
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [lowPoint, setLowPoint] = useState();
  const [typePiece, setTypePiece] = useState();
  const [offWeb, setOffWeb] = useState();
  const [unitCount, setUnitCount] = useState();
  const [price2, setPrice2] = useState();
  const [productStatus, setProductStatus] = useState();
  const [defaultLocation, setDefaultLocation] = useState();
  const [pending, setPending] = useState(false);
  const [pending2, setPending2] = useState(false);

  const { status, data } = useGetRequest(
    "inventory/details/" + props.productInventoryID
  );

  useEffect(() => {
    setProductCode(data?.data?.productCode);
    setProductDescription(data?.data?.description);
    setProductUnitPrice(data?.data?.unitPrice);
    setAdminOnly(data?.data?.adminOnly);
    setImage(data?.data?.image);
    setImage2(data?.data?.image2);
    setProductCategory(props.productTypeCode);
    setDivision(data?.data?.division);
    setCategory1(data?.data?.category1);
    setCategory2(data?.data?.category2);
    setCategory3(data?.data?.category3);
    setMin(data?.data?.min);
    setMax(data?.data?.max);
    setLowPoint(data?.data?.lowPoint);
    setTypePiece(data?.data?.typePiece);
    setOffWeb(data?.data?.offWeb);
    setUnitCount(data?.data?.unitCount);
    setPrice2(data?.data?.price2);
    setProductStatus(data?.data?.productStatus);
    setDefaultLocation(data?.data?.defaultLocation);
    setLocations(setInitialLocations(data?.data?.defaultLocation));
  }, [data]);

  // updating inventory
  const updateInventory = async (path, body) => {
    const res = await sendPostRequestMultipart(path, body);
    props.updatedNotyf();
  };

  const { mutateAsync: doUpdateMutation } = useMutation({
    mutationFn: (data) => {
      return updateInventory(data.path, { customerID, ...data.body });
    },
    onSuccess: () => {
      props.queryClient.invalidateQueries({ queryKey: ["inventory"] });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!category1 || category1 == 0) {
      alert("Please select a category 1");
      return;
    }

    doUpdateMutation({
      path: "inventory/update",
      body: {
        inventoryID: props.productInventoryID,
        productCode: productCode,
        productDescription: productDescription,
        productUnitPrice: productUnitPrice,
        adminOnly: adminOnly,
        typeCode: productCategory,
        image: image,
        deleteImage: deleteImage ? 1 : 0,
        image2: image2,
        deleteImage2: deleteImage2 ? 1 : 0,
        division: division,
        category1: category1,
        category2: category2,
        category3: category3,
        min: min,
        max: max,
        lowPoint: lowPoint,
        typePiece: typePiece,
        offWeb: offWeb,
        unitCount: unitCount,
        price2: price2,
        productStatus: productStatus,
        defaultLocation: locations.length > 0 ? locations : defaultLocation,
      },
    });
    props.setEditOpen(false);

    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };

  if (
    data?.data?.productCode &&
    status == "fetched" &&
    statusDropdownCategories1 == "fetched" &&
    statusDropdownCategories2 == "fetched" &&
    statusDropdownCategories3 == "fetched"
  ) {
    return (
      <Transition.Root show={props.editOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setEditOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => props.setEditOpen(false)}
                            ></button>
                          </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                          <table className="min-w-full divide-y divide-gray-300 mt-10">
                            <tbody className="divide-gray-200">
                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="code"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Product Code
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="text"
                                    name="code"
                                    id="code"
                                    value={productCode ? productCode : ""}
                                    onChange={(e) => {
                                      setProductCode(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Product Description
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="description"
                                    id="description"
                                    value={
                                      productDescription
                                        ? productDescription
                                        : ""
                                    }
                                    onChange={(e) => {
                                      setProductDescription(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="division"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Division
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <select
                                    id="division"
                                    name="division"
                                    onChange={(e) =>
                                      setDivision(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                  >
                                    <option value={props.divisionID}>
                                      {props.divisionName}
                                    </option>
                                    {props.dataDropdownDivisions?.divisions.map(
                                      (division) => (
                                        <option
                                          key={division.divisionID}
                                          value={division.divisionID}
                                        >
                                          {division.divisionName}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="category1"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Category 1
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <select
                                    id="category1"
                                    name="category1"
                                    onChange={(e) =>
                                      setCategory1(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                  >
                                    <option value={props.category1ID}>
                                      {props.category1Name}
                                    </option>
                                    <option value={0}></option>
                                    {dataDropdownCategories1?.categories?.map(
                                      (category) => (
                                        <option
                                          key={category.categoryID}
                                          value={category.categoryID}
                                        >
                                          {category.categoryName}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="category2"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Category 2
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <select
                                    id="category2"
                                    name="category2"
                                    onChange={(e) =>
                                      setCategory2(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                  >
                                    <option value={props.category2ID}>
                                      {props.category2Name}
                                    </option>
                                    <option value={0}></option>

                                    {dataDropdownCategories2?.categories?.map(
                                      (category) => (
                                        <option
                                          key={category.categoryID}
                                          value={category.categoryID}
                                        >
                                          {category.categoryName}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="category3"
                                    className="block text-sm font-medium leading-6 text-gray-600 sm:pt-1.5"
                                  >
                                    Category 3
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <select
                                    id="category3"
                                    name="category3"
                                    onChange={(e) =>
                                      setCategory3(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                  >
                                    <option value={props.category3ID}>
                                      {props.category3Name}
                                    </option>
                                    <option value={0}></option>

                                    {dataDropdownCategories3?.categories?.map(
                                      (category) => (
                                        <option
                                          key={category.categoryID}
                                          value={category.categoryID}
                                        >
                                          {category.categoryName}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="unitPrice"
                                    className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5"
                                  >
                                    Price
                                  </label>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="unitPrice"
                                    id="unitPrice"
                                    value={
                                      productUnitPrice ? productUnitPrice : ""
                                    }
                                    onChange={(e) => {
                                      setProductUnitPrice(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="adminOnly"
                                    className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5"
                                  >
                                    Admin Only
                                  </label>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="checkbox"
                                    name="adminOnly"
                                    id="adminOnly"
                                    checked={adminOnly === 1 ? true : false}
                                    onChange={(e) => {
                                      setAdminOnly(e.target.checked ? 1 : 0);
                                    }}
                                    className="block w-4 rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="min"
                                    className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5"
                                  >
                                    Min
                                  </label>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="min"
                                    id="min"
                                    value={min ? min : ""}
                                    onChange={(e) => {
                                      setMin(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="max"
                                    className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5"
                                  >
                                    Max
                                  </label>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="max"
                                    id="max"
                                    value={max ? max : ""}
                                    onChange={(e) => {
                                      setMax(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="lowPoint"
                                    className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5"
                                  >
                                    Low Point
                                  </label>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="lowPoint"
                                    id="lowPoint"
                                    value={lowPoint ? lowPoint : ""}
                                    onChange={(e) => {
                                      setLowPoint(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="typePiece"
                                    className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5"
                                  >
                                    Type Piece
                                  </label>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  {/* <input
                                    type="text"
                                    name="typePiece"
                                    id="typePiece"
                                    value={typePiece ? typePiece : ""}
                                    onChange={(e) => {
                                      setTypePiece(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  /> */}

                                  <select
                                    id="typePiece"
                                    name="typePiece"
                                    onChange={(e) =>
                                      setTypePiece(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                  >
                                    <option value={props.typePieceID}>
                                      {props.typePiece}
                                    </option>

                                    {dataTypePiece?.typePieces?.map((item) => (
                                      <option
                                        key={item.typePieceID}
                                        value={item.typePieceID}
                                      >
                                        {item.typePiece}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="offWeb"
                                    className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5"
                                  >
                                    Off Web
                                  </label>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="checkbox"
                                    name="offWeb"
                                    id="offWeb"
                                    checked={offWeb === 1 ? true : false}
                                    onChange={(e) => {
                                      setOffWeb(e.target.checked ? 1 : 0);
                                    }}
                                    className="block w-4 rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="unitCount"
                                    className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5"
                                  >
                                    Unit Count
                                  </label>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="unitCount"
                                    id="unitCount"
                                    value={unitCount ? unitCount : ""}
                                    onChange={(e) => {
                                      setUnitCount(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="price2"
                                    className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5"
                                  >
                                    Price 2
                                  </label>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <input
                                    type="text"
                                    name="price2"
                                    id="price2"
                                    value={price2 ? price2 : ""}
                                    onChange={(e) => {
                                      setPrice2(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="productStatus"
                                    className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5"
                                  >
                                    Status
                                  </label>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                  <select
                                    id="productStatus"
                                    name="productStatus"
                                    onChange={(e) =>
                                      setProductStatus(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                  >
                                    <option value={productStatus}>
                                      {productStatus}
                                    </option>

                                    <option key={"zero"} value={""}>
                                      {""}
                                    </option>

                                    <option key={"DISC"} value={"DISC"}>
                                      {"DISC"}
                                    </option>
                                    <option key={"HOLD"} value={"HOLD"}>
                                      {"HOLD"}
                                    </option>
                                  </select>
                                </td>
                              </tr>

                              {dataLocations.status !== "success" ? null : (
                                <>
                                  <tr>
                                    <td>
                                      {" "}
                                      <label
                                        htmlFor="region"
                                        className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5"
                                      >
                                        Locations
                                      </label>
                                    </td>

                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                      <label className="relative">
                                        <input
                                          type="checkbox"
                                          className="hidden peer"
                                        />
                                        {"Locations (+/-)"}

                                        <div className="absolute bg-white border transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto">
                                          <ul className="grid grid-cols-3 w-64">
                                            {dataLocations.locations.map(
                                              (option, i) => {
                                                return (
                                                  <li
                                                    className="mr-15"
                                                    key={option + i}
                                                  >
                                                    <label className="flex whitespace-nowrap cursor-pointer px-1 py-1 transition-colors hover:bg-blue-100 [&:has(input:checked)]:bg-blue-200">
                                                      {getCheckedLocation(
                                                        option.name
                                                      ) ? (
                                                        <input
                                                          type="checkbox"
                                                          name="locations"
                                                          value={option.name}
                                                          checked
                                                          onChange={() => {
                                                            addRemoveLocation(
                                                              option.name
                                                            );
                                                          }}
                                                          className="cursor-pointer"
                                                        />
                                                      ) : (
                                                        <input
                                                          type="checkbox"
                                                          name="locations"
                                                          value={option.name}
                                                          onChange={() => {
                                                            addRemoveLocation(
                                                              option.name
                                                            );
                                                          }}
                                                          className="cursor-pointer"
                                                        />
                                                      )}

                                                      <span className="ml-1 text-xs font-medium">
                                                        {option.name}
                                                      </span>
                                                    </label>
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      </label>
                                    </td>
                                  </tr>
                                </>
                              )}

                              <tr>
                                <td></td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="text"
                                    name="defaultLocation"
                                    id="defaultLocation"
                                    value={
                                      locations?.length > 0
                                        ? locations
                                        : defaultLocation
                                    }
                                    onChange={(e) => {
                                      setDefaultLocation(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </td>
                              </tr>

                              {image && pending == false ? (
                                <>
                                  <tr
                                    onClick={() => {
                                      setImage("");
                                      setDeleteImage(true);
                                    }}
                                  >
                                    <td colSpan={2}>
                                      <div className="text-xs text-red-500 text-end hover:cursor-pointer mr-4">
                                        DELETE
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2}>
                                      <img
                                        src={imageURL + image}
                                        className="h-full w-full object-cover object-center"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2}>
                                      {/* <div className="text-xs w-full">
                                        {image ? image : ""}
                                      </div>
                                      <div className="text-xs w-full">
                                        {image ? image.split("-").pop() : ""}
                                      </div> */}
                                      <div className="text-xs w-full">
                                        {image
                                          ? image.split("-")[1] +
                                            "-" +
                                            image.split("-")[2]
                                          : ""}
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : null}
                              <tr>
                                <td>
                                  {" "}
                                  <label
                                    htmlFor="image"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Update Image?
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="file"
                                    name="image"
                                    id="image"
                                    onChange={(event) => {
                                      setImage(event.target.files[0]);
                                      setPending(true);
                                    }}
                                  />
                                </td>
                              </tr>

                              {image2 && pending2 == false ? (
                                <>
                                  <tr
                                    onClick={() => {
                                      setImage2("");
                                      setDeleteImage2(true);
                                    }}
                                  >
                                    <td colSpan={2}>
                                      <div className="text-xs text-red-500 text-end hover:cursor-pointer mr-4">
                                        DELETE
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2}>
                                      {/* <img
                                        src={imageURL + image2}
                                        className="h-full w-full object-cover object-center"
                                      /> */}

                                      <object
                                        data={imageURL + image2}
                                        type="application/pdf"
                                        width="100%"
                                        height="100%"
                                      ></object>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2}>
                                      <div className="text-xs w-full">
                                        {image2
                                          ? image2.split("-")[1] +
                                            "-" +
                                            image2.split("-")[2]
                                          : ""}
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : null}
                              <tr>
                                <td className="w-[60%]">
                                  {" "}
                                  <label
                                    htmlFor="image2"
                                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                  >
                                    Update Image2?
                                  </label>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <input
                                    type="file"
                                    name="image2"
                                    id="image2"
                                    onChange={(event) => {
                                      setImage2(event.target.files[0]);
                                      setPending2(true);
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="mt-3">
                            <button
                              type="submit"
                              className="flex items-center justify-center rounded-md border border-transparent bg-amber-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700"
                            >
                              Edit Item
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
